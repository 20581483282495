import { PropsWithChildren } from "react";
import LogoTitle from "../molecules/LogoTitle";
import { Head } from "@inertiajs/react";
import { topKeyVisualUrl } from "@/api/image/api";

export default function TopLayout({
  pageTitle,
  isBackImage,
  children,
}: PropsWithChildren & { pageTitle: string; isBackImage?: boolean }) {
  const isBackImageStyle = isBackImage ? " text-white animate-fade-in" : "";
  const isBackImageTextHoverStyle = isBackImage
    ? " hover:text-gray-300"
    : " hover:text-gray-900";
  const isBackImagePaddingTopStyle = isBackImage ? " absolute" : " md:absolute";
  const isBackImageFooterStyle = isBackImage ? "" : " bg-white";

  return (
    <div
      className={
        "relative min-h-screen text-gray-600 body-font object-scale-down bg-cover " +
        isBackImageStyle
      }
      style={{
        backgroundImage: isBackImage ? `url(${topKeyVisualUrl()})` : "none",
        // backgroundSize: "100% 100%",
        backgroundPosition: "center",
      }}
    >
      <Head title={pageTitle} />

      <header
        className={
          "text-gray-600 body-font top-0 w-full" + isBackImagePaddingTopStyle
        }
      >
        <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center w-full">
          <LogoTitle isTop={isBackImage ? "topMain" : "topSub"} />

          <nav className="md:ml-auto flex flex-wrap items-center justify-center text-md font-bold">
            <a
              href={route("site_overview")}
              className={"mr-5" + isBackImageTextHoverStyle}
            >
              サイト概要
            </a>
            <a
              href={route("specification")}
              className={"mr-5" + isBackImageTextHoverStyle}
            >
              仕様
            </a>
            <a href={route("register")}>
              <div className="text-white text-sm rounded-3xl py-3 px-5 bg-gradient-to-l from-cyan-500 to-blue-500 hover:text-blue-900 hover:shadow-sm hover:shadow-gray-300">
                アカウント登録
              </div>
            </a>
          </nav>
        </div>
      </header>

      {children}

      <footer
        className={
          "body-font absolute bottom-0 w-full text-black font-bold " +
          isBackImageFooterStyle
        }
      >
        <div>
          <div className="container mx-auto py-4 px-5 flex flex-wrap flex-col sm:flex-row">
            <p className="text-md text-center sm:text-left">
              <a
                href="https://mushiki.com"
                className="ml-1"
                target="_blank"
                rel="noopener noreferrer"
              >
                © 2024 無式 Inc.
              </a>
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}
